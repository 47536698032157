<template>
  <div class="vm-banner d-flex align-center">
    <div class="swiper-container" id="lines-swiper">
      <div class="swiper-wrapper">
        <div class="swiper-slide d-flex align-center" v-if="slides.length" v-for="(item,i) in slides" :key="i">
          <div
            v-if="!item.videoSlider"
            class="vm-banner-bg"
            :style="{ backgroundImage: 'url(' + require('@/assets/images/'+ item.src) + ')' }"
          />
          <div v-if="item.videoSlider" class="vm-banner-bg video-banner">
            <video autoplay muted loop>
              <source src="@/assets/vids/Vuma-Manifesto-Snip.mp4" type="video/mp4">
            </video>
          </div>
          <v-container>
            <div class="header-wrap">
              <div class="bigger-header" v-html="item.title"></div>
              <div class="sub-header" v-html="item.subHeader"></div>
            </div>
            <div v-if="item.hasVideo">
              <v-dialog
                v-model="dialog"
                width="950"
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    rounded outlined dark
                    v-on="on"
                  >
                    Watch the video
                  </v-btn>
                </template>
                <v-card>
                  <v-responsive :aspect-ratio="16/9">
                    <iframe
                      width="100%" height="100%" :src="item.videoBtn" frameborder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen/>
                  </v-responsive>
                </v-card>
              </v-dialog>
              <v-btn
                rounded outlined dark
                class="move-to" to="/" data-section="vm-coverage-wrap"
                v-if="!item.hasVideo"
              >
                Check coverage
              </v-btn>
            </div>

            <div v-if="item.footer" class="sub-footer vuma-font-25" v-html="item.footer"></div>

          </v-container>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper'
import 'swiper/css/swiper.min.css'

export default {
  data () {
    return {
      dialog: false,
      slides: [
        {
          title: '<span style="font-weight: bold">Get connected from<br>only <sup>*</sup>R449, with line<br>speeds up to <sup>*</sup>1Gbps</span>',
          subHeader: '<a href="/omniscript" style="color: white; text-decoration: none">Click here for more deals</a>',
          src: 'home/banners/home-banner-4.jpg',
          hasVideo: true,
          videoSlider: false,
          video: '',
          videoBtn: 'https://www.youtube.com/embed/P2kgjM6HTmE',
          footer: '<sup>*</sup>ISP T&Cs apply | <sup>*</sup>Line speed is area dependent'
        },
      ],
    }
  },
  mounted () {
    new Swiper('#lines-swiper', {
      preloadImages: true,
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
      },
      paginationClickable: true,
      loop: true,
      speed: 600,
      centeredSlides: true,
      spaceBetween: 0,
    })
  },
}
</script>

<style lang="scss">
.vm-banner {
  width: 100%;
  height: 100vh;
  background: #000;
  color: #fff;
  position: relative;

  .swiper-container {
    height: 100%;
    width: 100%;

    .swiper-wrapper {
      height: 100%;
    }

    .swiper-pagination {
      .swiper-pagination-bullet {
        width: 12px;
        height: 12px;
        display: inline-block;
        border-radius: 100%;
        background: #fff;
        opacity: 1;

        &.swiper-pagination-bullet-active {
          background: #EE008C;
        }
      }
    }
  }

  .vm-banner-bg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-size: cover;
    background-position: center right;
    background-repeat: no-repeat;

    img {
      position: relative;
      transform: translateY(-5%);
      display: none;
    }

    &:before {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.3);
      position: absolute;
      top: 0;
      left: 0;
      z-index: 4;
    }
  }

  .container {
    z-index: 5;

    .header-wrap {
      width: 100%;
      line-height: 1;
      margin-left: 1rem;

      &.active {
        background-color: #000;
      }

      .small-header {
        font-size: 32px;
        font-family: "Core Sans R 55", "Roboto", sans-serif;
        margin-bottom: 2rem;
      }

      .bigger-header {
        font-size: 75px;
        margin-bottom: 1.5rem;

        strong {
          font-family: "Core Sans R 55", "Roboto", sans-serif;
        }
      }

      .sub-header {
        font-size: 34px;
        margin-bottom: 3rem;
        margin-top: 3rem;
        max-width: 750px;
      }
    }

    .v-btn {
      margin-left: 1rem;

      &:before {
        opacity: 0;
        background-color: #ee008c;
      }

      &:hover {
        border-color: #ee008c;
        background-color: #ee008c;
      }
    }
  }
}

video {
  position: absolute;
  z-index: 0;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  margin: auto;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}

.sub-footer {
  position: absolute;
  bottom: 1rem;
  margin-left: 1rem;
}
</style>